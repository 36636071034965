import {
  Box,
  colors,
  ContentText,
  fontWeights,
  Heading,
  LazyIconProps,
  lineHeights,
} from '@fortum/elemental-ui';
import FormattedEnhancedMessage from '@utils/intl-extenders/FormattedEnhancedMessage';
import Divider from '@components/Divider';
import { FeatureIconItem } from '@components/IconItems';
import { useTranslation } from 'react-i18next';
import React, { useEffect } from 'react';
import { GTMButton } from '@components/GTMComponents';
import useIsAdminPage from '@hooks/useIsAdminPage';
import { useLocation, useNavigate } from 'react-router-dom';
import SubpageLayout from '@containers/SubpageLayout';

function AddressChanged() {
  const contentfulCategory = 'addressChanged';
  const { t } = useTranslation();
  const isAdminContext = useIsAdminPage();
  const navigate = useNavigate();
  const location = useLocation();
  const state = location.state as {
    postal?: string;
    street?: string;
  };
  const steps = t<
    string,
    { text: string; icon: LazyIconProps['iconId']; label: string }[]
  >(`${contentfulCategory}.steps`, {
    returnObjects: true,
  });

  useEffect(() => {
    if (!state?.postal || !state?.street) navigate('/contract-summary');
  }, [navigate, state]);

  function onClick() {
    navigate('/contract-summary');
  }

  return (
    <SubpageLayout
      header={t('navigation.step.addressChanged')}
      headerTextStyle={{ marginLeft: '2rem' }}
    >
      <Box margin="1rem 0 0">
        <Heading
          weight={fontWeights.bold}
          lineHeight={lineHeights.tight}
          color={colors.inkGrey}
        >
          <FormattedEnhancedMessage i18nKey={`${contentfulCategory}.header`} />
        </Heading>
        <ContentText size="xl" color={colors.emperorGrey} marginBottom="2rem">
          <FormattedEnhancedMessage
            i18nKey={`${contentfulCategory}.subheader`}
            values={{ street: state?.street || '', city: state?.postal || '' }}
          />
        </ContentText>
        {Array.isArray(steps)
          ? steps.map((item) => (
              <React.Fragment key={item.label}>
                <FeatureIconItem
                  icon={item.icon}
                  label={item.label}
                  content={item.text}
                  iconProps={{ color: colors.forestGreen }}
                />
                <Divider />
              </React.Fragment>
            ))
          : null}
      </Box>
      <Box
        position="sticky"
        bottom="0"
        backgroundColor={colors.snowWhite}
        padding="1rem 1.5rem"
        margin="0 -1rem -1rem"
      >
        <ContentText
          color={colors.inkGrey}
          size="s"
          marginBottom="1rem"
          fontWeight={fontWeights.light}
          textAlign="center"
        >
          <FormattedEnhancedMessage i18nKey={`${contentfulCategory}.footer`} />
        </ContentText>
        <Box padding="0 0.5rem">
          <GTMButton
            width="100%"
            marginBottom="0.5rem"
            padding="0"
            onClick={() => onClick()}
            disabled={isAdminContext}
            contentfulKey={`${contentfulCategory}.button`}
          >
            <FormattedEnhancedMessage i18nKey="common.done" />
          </GTMButton>
        </Box>
      </Box>
    </SubpageLayout>
  );
}

export default AddressChanged;
