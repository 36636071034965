import {
  Box,
  IconFindContractor,
  ContentText,
  colors,
} from '@fortum/elemental-ui';

const NotFoundFallback: React.FC = () => {
  return (
    <Box
      padding="40% 1rem"
      flexDirection="column"
      display="flex"
      alignItems="center"
    >
      <IconFindContractor
        color={colors.ivyGreen600}
        size={80}
        marginBottom="1rem"
      />
      <ContentText
        color={colors.inkGrey}
        textAlign="center"
        fontWeight={700}
        marginBottom="1rem"
        size="xl"
      >
        Page cannot be found
      </ContentText>
      <ContentText
        size="s"
        color={colors.inkGrey}
        textAlign="center"
        fontWeight={400}
      >
        Address you chose is temporarily unavailable or the address is wrong.
      </ContentText>
    </Box>
  );
};

export default NotFoundFallback;
