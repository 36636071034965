import { Box, colors } from '@fortum/elemental-ui';
import styled from 'styled-components';

type Props = {
  hasValue?: boolean;
};

const Dot = styled.div`
  background-color: ${colors.ivyGreen600};
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
`;

const PinBox: React.FC<Props> = ({ hasValue }) => {
  return (
    <Box
      border={`2px solid ${hasValue ? colors.ivyGreen600 : colors.fogGrey}`}
      width="4rem"
      height="4rem"
      display="flex"
      justifyContent="center"
      alignItems="center"
      data-testid="PinBox"
    >
      {hasValue && <Dot />}
    </Box>
  );
};

export default PinBox;
