import {
  Box,
  IconPowerOutage,
  ContentText,
  colors,
} from '@fortum/elemental-ui';
import { useTranslation } from 'react-i18next';

const ErrorFallback: React.FC<{ error?: string }> = ({ error }) => {
  const { t } = useTranslation();

  return (
    <Box
      padding="40% 1rem"
      flexDirection="column"
      display="flex"
      alignItems="center"
    >
      <IconPowerOutage
        color={colors.ivyGreen600}
        size={80}
        marginBottom="1rem"
      />
      <ContentText
        color={colors.inkGrey}
        textAlign="center"
        fontWeight={700}
        marginBottom="1rem"
        size="xl"
      >
        {t('errors.somethingWentWrong.header')}
      </ContentText>
      <ContentText
        size="s"
        color={colors.inkGrey}
        textAlign="center"
        fontWeight={400}
      >
        {t(error || 'errors.somethingWentWrong.content')}
      </ContentText>
    </Box>
  );
};

export default ErrorFallback;
