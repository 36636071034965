import {
  Box,
  colors,
  ContentText,
  fontWeights,
  Heading,
  LazyIconProps,
  lineHeights,
} from '@fortum/elemental-ui';
import FormattedEnhancedMessage from '@utils/intl-extenders/FormattedEnhancedMessage';
import Divider from '@components/Divider';
import ReviewBox from '@components/ReviewBox';
import { FeatureIconItem, ImpactIconItem } from '@components/IconItems';
import { useTranslation } from 'react-i18next';
import React from 'react';
import Spinner from '@components/Spinner';
import { GTMButton } from '@components/GTMComponents';
import useIsAdminPage from '@hooks/useIsAdminPage';
import useRoutes from '@hooks/useRouting';

function LoyaltyProgram() {
  const contentfulCategory = 'loyaltyProgram';
  const { i18n, t } = useTranslation();
  const [toastLoading] = React.useState(false);
  const isAdminContext = useIsAdminPage();
  const { redirectToNext } = useRoutes();

  const review = {
    name: t(`${contentfulCategory}.review.name`),
    date: t(`${contentfulCategory}.review.date`),
    content: t(`${contentfulCategory}.review.content`),
    footer: t(`${contentfulCategory}.review.footer`),
  };

  const impactPoints = t<
    string,
    { text: string; icon: LazyIconProps['iconId'] }[]
  >(`${contentfulCategory}.impactPoint`, {
    returnObjects: true,
  });

  const featurePoints = t<
    string,
    { text: string; icon: LazyIconProps['iconId']; label: string }[]
  >(`${contentfulCategory}.featurePoint`, {
    returnObjects: true,
  });

  async function onClick() {
    return redirectToNext?.();
  }

  if (toastLoading) return <Spinner />;

  return (
    <>
      {i18n.exists(`${contentfulCategory}.image`) ? (
        <Box margin="0.5rem -1rem">
          <img
            src={t(`${contentfulCategory}.image`)}
            alt="running-girl"
            width="100%"
          />
        </Box>
      ) : null}
      <Box margin="1rem 0 0">
        <Heading
          weight={fontWeights.bold}
          lineHeight={lineHeights.tight}
          color={colors.inkGrey}
        >
          <FormattedEnhancedMessage i18nKey={`${contentfulCategory}.header`} />
        </Heading>
        <ContentText size="xl" color={colors.emperorGrey} marginBottom="2rem">
          <FormattedEnhancedMessage
            i18nKey={`${contentfulCategory}.subheader`}
          />
        </ContentText>
        {Array.isArray(featurePoints)
          ? featurePoints.map((item) => (
              <React.Fragment key={item.label}>
                <FeatureIconItem
                  icon={item.icon}
                  label={item.label}
                  content={item.text}
                />
                <Divider />
              </React.Fragment>
            ))
          : null}
        <Box margin="1rem 0">
          <ContentText size="xl" weight={fontWeights.bold} marginBottom="1rem">
            <FormattedEnhancedMessage i18nKey="common.reviews" />
          </ContentText>
          <ReviewBox data={review} />
        </Box>
        <Divider />
        <ContentText size="xl" weight={fontWeights.bold} margin="1rem 0">
          <FormattedEnhancedMessage
            i18nKey={`${contentfulCategory}.impactHeader`}
          />
        </ContentText>
        {Array.isArray(impactPoints)
          ? impactPoints.map((item) => (
              <ImpactIconItem
                key={item.icon}
                icon={item.icon}
                content={item.text}
              />
            ))
          : null}
      </Box>
      <Box
        position="sticky"
        bottom="0"
        backgroundColor={colors.snowWhite}
        padding="1rem 1.5rem"
        margin="0 -1rem -1rem"
      >
        <ContentText
          color={colors.inkGrey}
          size="s"
          marginBottom="0.5rem"
          fontWeight={fontWeights.light}
          textAlign="center"
        >
          <FormattedEnhancedMessage i18nKey={`${contentfulCategory}.footer`} />
        </ContentText>
        <Box padding="0 0.5rem">
          <GTMButton
            width="100%"
            padding="0"
            status="secondary"
            onClick={() => onClick()}
            disabled={toastLoading || isAdminContext}
            contentfulKey={`${contentfulCategory}.button`}
            borderRadius="28px"
          >
            <FormattedEnhancedMessage
              i18nKey={`${contentfulCategory}.button.content`}
            />
          </GTMButton>
        </Box>
      </Box>
    </>
  );
}

export default LoyaltyProgram;
