import { useTranslation } from 'react-i18next';
import KeyValueColumn from '@components/KeyValueColumn';
import FormattedEnhancedMessage from '@utils/intl-extenders/FormattedEnhancedMessage';
import {
  Box,
  ContentText,
  IconEmail,
  IconProfileOutline,
  colors,
  fontWeights,
} from '@fortum/elemental-ui';
import { MappedData } from './ContractSummary.types';

export function B2CInfoBox({ mappedData }: { mappedData: MappedData }) {
  const { t } = useTranslation();
  const { name, email } = mappedData;
  return (
    <Box margin="1rem 0">
      <ContentText
        size="xl"
        weight={fontWeights.bold}
        marginBottom="1rem"
        color={colors.ivyGreen900}
      >
        <FormattedEnhancedMessage i18nKey="common.customerInformation" />
      </ContentText>
      <KeyValueColumn
        label={t('common.name')}
        icon={
          <IconProfileOutline
            color={colors.ivyGreen600}
            marginRight="0.75rem"
          />
        }
      >
        {name}
      </KeyValueColumn>
      <KeyValueColumn
        label={t('common.email')}
        icon={<IconEmail color={colors.ivyGreen600} marginRight="0.75rem" />}
      >
        {email}
      </KeyValueColumn>
      {/* <ContentText
      size="s"
      color={colors.emperorGrey}
      marginTop="1rem"
      onClick={handleEmailChangeClick}
    >
      <FormattedEnhancedMessage
        i18nKey={`${contentfulCategory}.wrongEmailAddress`}
      />
      <FormattedEnhancedMessage
        i18nKey={`${contentfulCategory}.wrongEmailAddressLink.text`}
      />
    </ContentText> */}
    </Box>
  );
}
