import { Box, colors, ContentText, fontWeights } from '@fortum/elemental-ui';
import { ClickableQuestionItem } from '@components/QuestionItem';
import { useTranslation } from 'react-i18next';
import FormattedEnhancedMessage from '@utils/intl-extenders/FormattedEnhancedMessage';

interface QuestionItemProps {
  image: string;
  text: string;
  children: React.ReactNode;
}

const CustomQuestionItem: React.FC<QuestionItemProps> = ({
  image,
  text,
  children,
}) => (
  <ClickableQuestionItem
    containerProps={{ marginBottom: '1rem', padding: '1rem' }}
    image={image}
    text={text}
  >
    {children}
  </ClickableQuestionItem>
);

function FAQList() {
  const contentfulCategory = 'faq';
  const { t } = useTranslation();

  const questions = t<string, { title: string; image: string; text: string }[]>(
    `${contentfulCategory}.question`,
    {
      returnObjects: true,
    }
  );
  return (
    <Box margin="1rem 0 0">
      <ContentText
        size="xl"
        weight={fontWeights.bold}
        margin="1rem 0"
        color={colors.ivyGreen900}
      >
        <FormattedEnhancedMessage i18nKey={`${contentfulCategory}.header`} />
      </ContentText>
      {Array.isArray(questions)
        ? questions.map(({ title, image, text }) => (
            <CustomQuestionItem image={image} text={text} key={title}>
              {title}
            </CustomQuestionItem>
          ))
        : null}
    </Box>
  );
}

export default FAQList;
