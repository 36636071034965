import {
  Box,
  colors,
  ContentText,
  IconProps,
  fontWeights,
  ContentTextProps,
  BoxProps,
  LazyIcon,
  LazyIconProps,
} from '@fortum/elemental-ui';

interface ItemProps {
  children: React.ReactNode;
  icon: LazyIconProps['iconId'];
  contentProps?: ContentTextProps;
  iconProps?: IconProps;
  containerProps?: BoxProps;
}

const BasicIconItem: React.FC<ItemProps> = ({
  icon,
  contentProps,
  iconProps,
  containerProps,
  children,
}) => {
  if (!children) return null;

  return (
    <Box display="flex" marginBottom="0.5rem" {...containerProps}>
      <LazyIcon
        iconId={icon}
        color={colors.snowWhite}
        marginRight="1rem"
        {...iconProps}
      />
      <ContentText
        color={colors.snowWhite}
        weight={fontWeights.regular}
        {...contentProps}
      >
        {children}
      </ContentText>
    </Box>
  );
};

export default BasicIconItem;
