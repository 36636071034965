import {
  Box,
  colors,
  ContentText,
  fontWeights,
  IconArrowRight,
  IconHome,
  IconPin,
  Link,
  px2rem,
} from '@fortum/elemental-ui';
import KeyValueRow from '@components/KeyValueRow';
import Divider from '@components/Divider';
import KeyValueColumn from '@components/KeyValueColumn';
import FormattedEnhancedMessage from '@utils/intl-extenders/FormattedEnhancedMessage';
import React, { useState } from 'react';
import AddressChangeBox from '@containers/AddressChangeBox';
import Consents from '@components/Consents';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import Spinner from '@components/Spinner';
import { getProperPriceString } from '@utils/prices';
import { GTMButton } from '@components/GTMComponents';
import KeyValuePrice from '@components/KeyValuePrice';
import useContractContext from '@hooks/useContractContext';
import useIsAdminPage from '@hooks/useIsAdminPage';
import ReadMoreContractModal from '@containers/ReadMoreContractModal';
import useRoutes from '@hooks/useRouting';
import DividerDashed from '@components/DividerDashed';
import {
  Address,
  ContractDetails,
  CustomerType,
  MonthlyFeeType,
} from '../../types/api';
import ErrorFallback from '../ErrorFallback';
import { UpdateEmailClickable } from './styled';
import { ISpecialPricesProps, MappedData } from './ContractSummary.types';
import { B2BInfoBox } from './B2BInfoBox';
import { B2CInfoBox } from './B2CInfoBox';

function SpecialPrices({ prices }: ISpecialPricesProps) {
  const { t } = useTranslation();

  if (!prices || !prices.length) return null;
  return (
    <>
      <DividerDashed />
      {Object.entries(prices).map(([key, value]) => (
        <KeyValueRow key={key} label={t(`common.${key}`)}>
          {getProperPriceString(value, t)}
        </KeyValueRow>
      ))}
    </>
  );
}

function getName(name?: string | string[]) {
  if (!name) return '';
  if (Array.isArray(name)) return name.join(', ');
  return name;
}

function formatAddress(address: Address) {
  if (!address) return '';
  return `${address.streetName || ''} ${address.houseNumber || ''} ${
    address.houseLetter || ''
  } ${address.residence || ''} ${address.postalCode || ''}, ${
    address.postalCity || ''
  }`;
}

const ContractStartsBox = ({
  contractDetails,
}: {
  contractDetails: ContractDetails;
}) => {
  const { t } = useTranslation();
  const contentfulCategory = 'contractDetails';

  function getContractStartDays() {
    if (!contractDetails) return 0;
    const from = dayjs(contractDetails.duration?.from);

    const hourDiff = from.diff(dayjs(), 'hour');

    const daysDiff = Math.ceil(hourDiff / 24);

    return daysDiff;
  }

  const contractStartsDays = getContractStartDays();

  const getContractStartsDaysString = () => {
    const isSame = dayjs(contractDetails.duration?.from).isSame(dayjs(), 'day');

    if (isSame) {
      return t(`${contentfulCategory}.today`);
    }
    if (contractStartsDays === 1) {
      return t(`${contentfulCategory}.tomorrow`);
    }
    if (contractStartsDays < 0) {
      return dayjs(contractDetails.duration?.from).format('DD.MM.YYYY');
    }
    return `${contractStartsDays} ${t(`${contentfulCategory}.days`)}`;
  };

  if (
    contractStartsDays === 0 ||
    contractStartsDays === 1 ||
    contractStartsDays < 0
  ) {
    return (
      <>
        <ContentText
          fontWeight={400}
          fontSize="14px"
          color="#333333"
          textAlign="center"
        >
          {t(
            contractStartsDays < 0
              ? `${contentfulCategory}.contractStarted`
              : `${contentfulCategory}.contractStarts`
          )}
        </ContentText>
        <ContentText
          color={colors.ivyGreen900}
          fontWeight={500}
          fontSize="29px"
          textAlign="center"
          marginBottom="1rem"
        >
          {getContractStartsDaysString()}
        </ContentText>
      </>
    );
  }

  return (
    <>
      <ContentText
        color={colors.ivyGreen900}
        fontWeight={500}
        fontSize="29px"
        textAlign="center"
      >
        {getContractStartsDaysString()}
      </ContentText>
      <ContentText
        fontWeight={400}
        fontSize="14px"
        color="#333333"
        textAlign="center"
        marginBottom="1rem"
      >
        {t(`${contentfulCategory}.untilContractStarts`)}
      </ContentText>
    </>
  );
};

function ContractSummary() {
  const contentfulCategory = 'contractDetails';
  const [showAddressChange, setShowAddressChange] = React.useState(false);
  const { t, i18n } = useTranslation();
  const { contractDetails, isLoading, isError } = useContractContext();
  const isAdminContext = useIsAdminPage();
  const [readMoreOpen, setReadMoreOpen] = useState(false);
  const { redirectToNext } = useRoutes();

  if (isError) return <ErrorFallback />;
  if (isLoading || !contractDetails) return <Spinner />;

  const mappedData: MappedData = {
    customerId: contractDetails.customerId,
    electricityPrice: contractDetails.electricityPrice,
    isHourlyPriced: contractDetails.electricityPrice?.isHourlyPriced || false,
    monthlyFee: contractDetails.monthlyFee,
    duration: {
      from: dayjs(contractDetails.duration?.from).format('D.MM.YYYY') || '',
      to: contractDetails.duration?.to
        ? dayjs(contractDetails.duration.to).format('D.MM.YYYY')
        : null,
    },
    contractNumber:
      contractDetails.contractCode || contractDetails.contractNumber,
    name: getName(contractDetails.name),
    email: contractDetails.email || '',
    address: contractDetails.address || '',
    meteringPointNo: contractDetails.meteringPointId || '',
    productName: contractDetails.productName || '',
    specialElectricityPrice: contractDetails.specialElectricityPrice || null,
    customerType: contractDetails.customerType,
  };

  const serializedProductName = mappedData.productName
    .toLowerCase()
    .replaceAll(' ', '-');

  // eslint-disable-next-line no-nested-ternary
  const extraPriceText = i18n.exists(`${contentfulCategory}.extraPriceText`)
    ? mappedData.customerType === CustomerType.PRIVATE
      ? t(`${contentfulCategory}.extraPriceText`)
      : t(`${contentfulCategory}.B2B_extraPriceText`)
    : null;

  function handleAddressChangeClick() {
    setShowAddressChange(true);
  }

  function onReadMoreModalClose() {
    setReadMoreOpen(false);
  }

  function getContractStartDays() {
    if (!contractDetails) return 0;
    const from = dayjs(contractDetails.duration?.from);
    if (dayjs().isAfter(from)) return 0;
    const diffInDays = from.diff(dayjs(), 'days');

    return diffInDays;
  }

  const contractStartsDays = getContractStartDays();

  const { monthlyFee } = mappedData;

  const getMonthlyFeeData = () => {
    if (!monthlyFee) return null;
    const baseFeeDiscount = getProperPriceString(monthlyFee, t, true);
    const baseFeeFrom = monthlyFee
      ? dayjs(monthlyFee.discountFrom).format('D.MM.YYYY')
      : 'None';
    const baseFeeTo = monthlyFee
      ? dayjs(monthlyFee.discountTo).format('D.MM.YYYY')
      : 'None';

    return { baseFeeDiscount, baseFeeFrom, baseFeeTo };
  };

  const monthlyFeeData = getMonthlyFeeData();

  return (
    <>
      <Box
        backgroundColor={colors.snowWhite}
        boxShadow="0px 0px 27px 8px rgba(0, 0, 0, 0.1)"
        padding="1rem"
        borderRadius="0.5rem"
        marginTop="1rem"
      >
        {contractStartsDays === 0 ? null : (
          <>
            <ContractStartsBox contractDetails={contractDetails} />
            <Divider />
          </>
        )}
        <Box marginTop="1rem">
          <ContentText
            size={20}
            color={colors.ivyGreen900}
            fontWeight={500}
            marginBottom="0.5rem"
          >
            {mappedData.productName}
          </ContentText>
          <ContentText size={14} color="#333333">
            {t(`products.${serializedProductName}.description`)}
          </ContentText>
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <Link
            fontSize="14px"
            marginTop="0.5rem"
            color="#0F7A5A"
            onClick={() => {
              setReadMoreOpen(true);
            }}
            fontWeight={400}
          >
            <FormattedEnhancedMessage i18nKey="common.readMore" />
          </Link>
        </Box>
        <DividerDashed />
        {mappedData.electricityPrice ? (
          <>
            <KeyValuePrice
              label={t(
                mappedData.isHourlyPriced
                  ? 'common.hourlyPrice'
                  : 'common.electricityPrice'
              )}
              priceObject={mappedData.electricityPrice}
              additionalText=""
            />
            <DividerDashed />
          </>
        ) : null}
        {mappedData.monthlyFee ? (
          <KeyValuePrice
            label={t(
              mappedData.monthlyFee?.type === MonthlyFeeType.flat
                ? 'common.flatFee'
                : 'common.monthlyFee'
            )}
            priceObject={mappedData.monthlyFee}
            additionalText={
              monthlyFee.discount
                ? t(`${contentfulCategory}.feeFreeSixMonths`, {
                    from: monthlyFeeData?.baseFeeFrom,
                    to: monthlyFeeData?.baseFeeTo,
                    discount: monthlyFeeData?.baseFeeDiscount,
                  })
                : ''
            }
          />
        ) : null}

        <SpecialPrices prices={mappedData.specialElectricityPrice} />
      </Box>
      <AddressChangeBox
        show={showAddressChange}
        setShow={setShowAddressChange}
      />
      <ReadMoreContractModal
        productName={serializedProductName}
        productTitle={mappedData.productName}
        open={readMoreOpen}
        onClose={onReadMoreModalClose}
      />

      {/* <SummaryBox
        header={mappedData.productName}
        content={t(`products.${serializedProductName}.description`)}
        containerProps={{
          backgroundColor: colors.emeraldGreen,
          marginTop: '1.5rem',
          marginBottom: '1rem',
          alignItems: 'center',
          padding: '2rem',
        }}
        contentProps={{ textAlign: 'center' }}
        icon={t(`products.${serializedProductName}.icon`)}
        onReadMoreClick={() => {
          setReadMoreOpen(true);
        }}
      /> */}
      {/* {mappedData.monthlyFee && (
        <DiscountValidity priceObject={mappedData.monthlyFee} />
      )} */}
      <Box margin="1rem 0">
        {/* <ContentText size="xl" weight={fontWeights.bold} marginBottom="1rem">
          <FormattedEnhancedMessage i18nKey="common.agreement" />
        </ContentText> */}

        <KeyValueRow label={t('common.duration')}>
          {mappedData.duration.from}
          {mappedData.duration.to ? (
            <>
              <IconArrowRight
                margin={`0 ${px2rem(12)}`}
                size={16}
                color={colors.ivyGreen600}
              />
              {mappedData.duration.to}
            </>
          ) : null}
        </KeyValueRow>
        <KeyValueRow label={t('common.contractNumber')}>
          {mappedData.contractNumber}
        </KeyValueRow>
        <KeyValueRow label={t('common.customerId')}>
          {mappedData.customerId}
        </KeyValueRow>
        {extraPriceText && (
          <ContentText size="s" color={colors.lavenderPurple600}>
            {extraPriceText}
          </ContentText>
        )}
      </Box>
      <Divider />
      {mappedData.customerType === CustomerType.BUSINESS ? (
        <B2BInfoBox mappedData={mappedData} />
      ) : (
        <B2CInfoBox mappedData={mappedData} />
      )}
      <Divider />
      <Box margin="1rem 0">
        <ContentText
          size="xl"
          weight={fontWeights.bold}
          marginBottom="1rem"
          color={colors.ivyGreen900}
        >
          <FormattedEnhancedMessage i18nKey="common.powerDeliveryAddress" />
        </ContentText>
        <KeyValueColumn
          label={t('common.address')}
          icon={<IconHome color={colors.ivyGreen900} marginRight="0.75rem" />}
        >
          {formatAddress(mappedData.address)}
        </KeyValueColumn>
        <KeyValueColumn
          label={t('common.meteringPointNo')}
          icon={<IconPin color={colors.ivyGreen900} marginRight="0.75rem" />}
        >
          {mappedData.meteringPointNo}
        </KeyValueColumn>
        <Box marginTop="1rem" display="flex">
          <ContentText size="s" color={colors.emperorGrey}>
            <FormattedEnhancedMessage
              i18nKey={`${contentfulCategory}.wrongDeliveryAddress`}
            />
            &nbsp;
            <UpdateEmailClickable onClick={handleAddressChangeClick}>
              <FormattedEnhancedMessage
                i18nKey={`${contentfulCategory}.wrongDeliveryAddressLink.text`}
              />
            </UpdateEmailClickable>
          </ContentText>
        </Box>
      </Box>
      <GTMButton
        width="-webkit-fill-available"
        margin="1rem"
        onClick={redirectToNext}
        contentfulKey={`${contentfulCategory}.button`}
        disabled={isAdminContext}
        borderRadius="28px"
      >
        <FormattedEnhancedMessage i18nKey="common.next" />
      </GTMButton>
      <Consents
        prefix={mappedData.customerType === CustomerType.BUSINESS ? 'B2B_' : ''}
      />
    </>
  );
}

export default ContractSummary;
