import {
  Box,
  colors,
  ContentText,
  fontWeights,
  lineHeights,
} from '@fortum/elemental-ui';
import FormattedEnhancedMessage from '@utils/intl-extenders/FormattedEnhancedMessage';
import { useTranslation } from 'react-i18next';
import { getProperPriceString } from '@utils/prices';
import { Addon } from 'types/api';
import BottomModal from '@containers/BottomModal';

interface Props {
  open: boolean;
  addon: Addon | null;
  onClose: () => void;
  active?: boolean;
}

function ReadMoreVasModal({ open, addon, onClose, active }: Props) {
  const contentfulCategory = 'recommendedVasReadMore';
  const { i18n, t } = useTranslation();

  return (
    <BottomModal show={open} setShow={onClose} showCloseButton>
      {i18n.exists(`${contentfulCategory}.image`) ? (
        <Box margin="0.5rem -1rem">
          <img
            src={t(`${contentfulCategory}.image`)}
            alt="thinking-woman"
            width="100%"
          />
        </Box>
      ) : null}
      <Box margin="1rem 0">
        <Box
          display="flex"
          flexDirection="column"
          alignItems="flex-start"
          justifyContent="space-between"
          marginBottom="1.5rem"
        >
          <ContentText size="xl" lineHeight={lineHeights.tight}>
            {addon ? t(`addons.${addon.tariffId}.title`) : ''}
          </ContentText>
          <ContentText size="xl" color={colors.ivyGreen600}>
            {getProperPriceString(
              addon ? addon.prices : { price: 0, unit: '€/mnth' },
              t
            )}
          </ContentText>
        </Box>
        <ContentText
          color={colors.inkGrey}
          weight={fontWeights.light}
          lineHeight={lineHeights.loose}
        >
          <FormattedEnhancedMessage
            i18nKey={`addons.${addon?.tariffId}.description${
              active ? 'Chosen' : ''
            }`}
          />
        </ContentText>
      </Box>
    </BottomModal>
  );
}

export default ReadMoreVasModal;
