import {
  BoxProps,
  colors,
  ContentTextProps,
  fontWeights,
  IconProps,
} from '@fortum/elemental-ui';
import BasicIconItem from './BasicIconItem';

interface Props {
  iconProps?: IconProps;
  contentProps?: ContentTextProps;
  containerProps?: BoxProps;
  children: React.ReactNode;
}

const tickContentProps = { color: colors.inkGrey, weight: fontWeights.light };
const tickIconProps = { color: colors.ivyGreen600, marginRight: '0.75rem' };
const tickIconContainerProps = { alignItems: 'flex-start' };

const TickItem: React.FC<Props> = ({
  iconProps = {},
  contentProps = {},
  containerProps = {},
  children,
}) => (
  <BasicIconItem
    icon="IconCheck"
    containerProps={{ ...tickIconContainerProps, ...containerProps }}
    contentProps={{ ...tickContentProps, ...contentProps }}
    iconProps={{ ...tickIconProps, ...iconProps }}
  >
    {children}
  </BasicIconItem>
);

export default TickItem;
