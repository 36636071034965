import {
  Box,
  Header,
  Heading,
  Button,
  CommonHeaderProps,
  ContentText,
  colors,
} from '@fortum/elemental-ui';
import useAdminContext from '@hooks/useAdminContext';
import useContractContext from '@hooks/useContractContext';
import { adminGetPreview } from '@utils/API';
import { AxiosError } from 'axios';
import { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import Spinner from '@components/Spinner';
import useAppContext from '@hooks/useAppContext';
import LanguageSwitcher from '@components/LanguageSwitcher';
import { CustomerType } from 'types/api';
import {
  ContractSummary,
  FAQBills,
  Invoice,
  LandingPage,
  LoyaltyProgram,
  RecommendedVas,
  ThankYou,
} from '..';

const StyledHeader = styled(Header)<CommonHeaderProps>`
  border-bottom: 1px solid lightgray;
  padding-bottom: 1rem;
`;

function LeftComponent() {
  return <Heading>Flow preview</Heading>;
}

function RightComponent() {
  const { logout } = useAdminContext();
  const navigate = useNavigate();
  const onClick = () => {
    logout();
    navigate('/admin/login');
  };

  return (
    <Box display="flex" gap="2rem">
      <LanguageSwitcher />
      <Button onClick={onClick}>Logout</Button>
    </Box>
  );
}

function AdminPreview() {
  const { authToken, logout, tokenSet } = useAdminContext();
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();
  const { id } = useParams<{ id: string }>();
  const { setContractDetails, contractDetails } = useContractContext();
  const [currentTab, setCurrentTab] = useState(1);
  const { vasPromotionDisable } = useAppContext();

  const getVisibilityLabel = () => {
    if (currentTab === 3 && vasPromotionDisable)
      return 'Hidden (config.disableVasPromotion is true)';
    if (
      currentTab === 4 &&
      contractDetails?.customerType === CustomerType.BUSINESS
    )
      return 'Hidden (B2B Contract)';
    return 'Visible';
  };

  const screens = [
    {
      id: 1,
      component: <LandingPage />,
      name: 'Landing page',
    },
    {
      id: 2,
      component: <ContractSummary />,
      name: 'Contract summary',
    },
    {
      id: 3,
      component: <RecommendedVas />,
      name: 'Recommended vas',
    },
    {
      id: 4,
      component: <LoyaltyProgram />,
      name: 'Loyalty program',
    },
    {
      id: 5,
      component: <Invoice />,
      name: 'Invoice summary',
    },
    {
      id: 6,
      component: <FAQBills />,
      name: 'FAQ',
    },
    {
      id: 7,
      component: <ThankYou />,
      name: 'Thank You',
    },
  ];

  const getData = useCallback(async () => {
    if (!id) return;
    try {
      setLoading(true);
      const resp = await adminGetPreview({
        authToken,
        id,
      });
      setContractDetails(resp);
    } catch (error) {
      if (
        error instanceof AxiosError &&
        (error.response?.status === 403 || error.response?.status === 401)
      ) {
        logout();
        navigate('/admin/login', { state: { from: location.pathname } });
      }
    } finally {
      setLoading(false);
    }
  }, [authToken, id, logout, navigate, setContractDetails, location]);

  useEffect(() => {
    if (!tokenSet) return;
    getData();
  }, [getData, tokenSet]);

  if (loading) return <Spinner />;

  const CurrentStep = () =>
    screens.find((screen) => screen.id === currentTab)?.component || null;

  return (
    <>
      <StyledHeader
        leftComponent={LeftComponent}
        rightComponent={RightComponent}
      />
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        gap="10px"
        padding="0.5rem 0"
        borderBottom={`1px solid ${colors.fogGrey}`}
        backgroundColor={colors.cloudGrey}
        top={0}
        position="sticky"
        zIndex={1000}
      >
        <Box display="flex" gap="0.5rem">
          <Button
            onClick={() =>
              setCurrentTab((prev) => (prev === 1 ? prev : prev - 1))
            }
            disabled={currentTab === 1}
            size="m"
          >
            Prev
          </Button>
          <Button
            onClick={() =>
              setCurrentTab((prev) =>
                prev === screens.length ? prev : prev + 1
              )
            }
            disabled={currentTab === screens.length}
            size="m"
          >
            Next
          </Button>
        </Box>
        <ContentText>{screens[currentTab - 1].name}</ContentText>
        <ContentText>{getVisibilityLabel()}</ContentText>
        <ContentText>
          {currentTab} / {screens.length} steps
        </ContentText>
      </Box>
      <Box
        padding="1rem"
        display="flex"
        flexDirection="column"
        height={currentTab === 1 ? '100%' : 'initial'}
        backgroundColor={currentTab === 7 ? colors.lightGrey : 'transparent'}
      >
        <CurrentStep />
      </Box>
    </>
  );
}

export default AdminPreview;
