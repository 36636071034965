import { colors, LazyIconProps } from '@fortum/elemental-ui';
import BasicIconItem from './BasicIconItem';

interface ImpactIconItemProps {
  icon: LazyIconProps['iconId'];
  content: string;
}

const ImpactIconItem: React.FC<ImpactIconItemProps> = ({ icon, content }) => (
  <BasicIconItem
    icon={icon}
    iconProps={{ color: colors.emperorGrey, size: '1.5rem' }}
    contentProps={{ color: colors.emperorGrey }}
    containerProps={{ margin: '0 0 1rem' }}
  >
    {content}
  </BasicIconItem>
);

export default ImpactIconItem;
