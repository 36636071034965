import { Box } from '@fortum/elemental-ui';
import styled from 'styled-components';

export const Container = styled(Box)`
  background: linear-gradient(161.56deg, #115039 0%, #0c3727 100%);
  position: relative;
  padding: 1rem;
  overflow: hidden;
  height: 100%;
`;

export const BackgroundImage = styled.img`
  position: absolute;
  bottom: 1rem;
  left: 0;
`;

export const BaseGradient = styled.div`
  background: linear-gradient(161.56deg, #0f7a5a 0%, #1e4f45 100%);

  position: absolute;
  width: 800px;
  height: 800px;
  left: -266px;
  top: -284px;
  z-index: -1;
  border-radius: 50%;
`;

export const BackgroundGradient = styled.div`
  position: absolute;
  width: 800px;
  height: 800px;
  left: -167px;
  top: -272px;
  border-radius: 50%;
  z-index: -2;
  background: radial-gradient(
    58.58% 19.34% at 50% 76.09%,
    #4da577 0%,
    #0f7a5a 100%
  );
`;
