import { useNavigate } from 'react-router-dom';
import { useCallback, useEffect, useState } from 'react';
import { ContractDetails, CustomerType } from 'types/api';
import useAddonsContext from './useAddonsContext';
import useContractContext from './useContractContext';
import useCurrentPath from './useCurrentPath';
import useAppContext from './useAppContext';

export type RenderedRoute = {
  path?: string;
  name?: string;
  order?: number;
  shouldRender?: boolean;
  id?: string;
};

const additionalRoutes: RenderedRoute[] = [
  {
    path: '/',
  },
  {
    path: '/bills-faq',
  },
];

const getMainFlowRoutes = (customRoutes: RenderedRoute[]) => [
  {
    name: 'navigation.step.yourContract',
    path: '/contract-summary',
    order: 1,
    shouldRender: true,
  },
  ...customRoutes,
  {
    name: 'navigation.step.yourInvoices',
    path: '/your-invoices',
    order: 3,
    shouldRender: true,
  },
  {
    name: 'navigation.step.thankYou',
    path: '/thank-you',
    order: 4,
    previousRoute: '/your-invoices',
    shouldRender: true,
  },
];

export function mapSteps(
  step: RenderedRoute,
  contractDetails: ContractDetails | undefined,
  recommendedActivated: boolean,
  vasPromotionDisable = false
): RenderedRoute {
  // if (index === 0) return { ...step, previousRoute: '/contract-summary' };
  if (step.id?.includes('loyaltyProgram'))
    return {
      ...step,
      path: '/loyalty-program',
      name: 'navigation.step.loyaltyProgram',
      shouldRender: contractDetails?.customerType === CustomerType.PRIVATE,
    };
  if (step.id?.includes('recommendedVas')) {
    return {
      ...step,
      path: '/recommended-vas',
      name: 'navigation.step.yourServices',
      shouldRender: !!(
        !recommendedActivated &&
        contractDetails?.recommendedAddon &&
        !vasPromotionDisable
      ),
    };
  }

  return { ...step, shouldRender: true };
}

export default function useRoutes() {
  const { recommendedActivated } = useAddonsContext();
  const { contractDetails } = useContractContext();
  const [mainFlowRoutes, setMainFlowRoutes] = useState<RenderedRoute[]>([]);
  const { fetchedRoutes, vasPromotionDisable } = useAppContext();

  const combinedRoutes = [...additionalRoutes, ...mainFlowRoutes];

  const getPreviousRoute = useCallback(
    (index: number): string => {
      if (index === 0) return '/';
      if (index === 1) return '/contract-summary';
      return mainFlowRoutes[index - 1].shouldRender
        ? (mainFlowRoutes[index - 1].path as string)
        : getPreviousRoute(index - 1);
    },
    [mainFlowRoutes]
  );

  const getNextRoute = useCallback(
    (index: number): string => {
      if (index === mainFlowRoutes.length - 1) return '/thank-you';
      return mainFlowRoutes[index + 1].shouldRender
        ? (mainFlowRoutes[index + 1].path as string)
        : getNextRoute(index + 1);
    },
    [mainFlowRoutes]
  );

  const currentRoute = useCurrentPath({
    routes: combinedRoutes,
  });

  const currentRouteConfigIndex = mainFlowRoutes.findIndex(
    (curr) => currentRoute && curr.path?.includes(currentRoute)
  );

  const navigate = useNavigate();

  function redirectToPrevious() {
    if (currentRouteConfigIndex === 0) return;
    navigate(getPreviousRoute(currentRouteConfigIndex));
  }

  function redirectToNext() {
    navigate(getNextRoute(currentRouteConfigIndex));
  }

  useEffect(() => {
    const routes = getMainFlowRoutes(
      fetchedRoutes.map((val) =>
        mapSteps(
          val,
          contractDetails,
          recommendedActivated,
          vasPromotionDisable
        )
      )
    );

    setMainFlowRoutes([...routes]);
  }, [
    contractDetails,
    recommendedActivated,
    fetchedRoutes,
    vasPromotionDisable,
  ]);
  return {
    redirectToPrevious,
    currentRoute,
    additionalRoutes,
    mainFlowRoutes,
    redirectToNext,
  };
}
