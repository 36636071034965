import { LazyIconProps } from '@fortum/elemental-ui';
import React from 'react';

interface Notification {
  text: string;
  icon?: LazyIconProps['iconId'];
  visible: boolean;
  color?: string;
}

interface NotificationContextType {
  notification: Notification;
  setNotification: React.Dispatch<React.SetStateAction<Notification>>;
}

export const NotificationContext = React.createContext<NotificationContextType>(
  {} as NotificationContextType
);

export function NotificationProvider({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element {
  const [notification, setNotification] = React.useState<Notification>({
    text: '',
    icon: 'IconError',
    visible: false,
  });

  const memoizedValue = React.useMemo(
    () => ({
      notification,
      setNotification,
    }),
    [notification, setNotification]
  );

  return (
    <NotificationContext.Provider value={memoizedValue}>
      {children}
    </NotificationContext.Provider>
  );
}
