/* eslint-disable jsx-a11y/anchor-is-valid */
import {
  Box,
  Button,
  colors,
  ContentText,
  fontWeights,
  lineHeights,
  Link,
} from '@fortum/elemental-ui';
import Divider from '@components/Divider';
import ReviewBox from '@components/ReviewBox';
import FormattedEnhancedMessage from '@utils/intl-extenders/FormattedEnhancedMessage';
import { Navigate } from 'react-router-dom';
import { TickItem } from '@components/IconItems';
import { useTranslation } from 'react-i18next';
import React from 'react';
import Spinner from '@components/Spinner';
import { getProperPriceString } from '@utils/prices';
import ReadMoreVasModal from '@containers/ReadMoreVasModal';
import useAddonsContext from '@hooks/useAddonsContext';
import useNotificationContext from '@hooks/useNotificationContext';
import { GTMButton } from '@components/GTMComponents';
import useContractContext from '@hooks/useContractContext';
import useIsAdminPage from '@hooks/useIsAdminPage';
import useRoutes from '@hooks/useRouting';
import ErrorFallback from '../ErrorFallback';

function RecommendedVas() {
  const { i18n, t } = useTranslation();
  const [toastLoading, setToastLoading] = React.useState(false);
  const [readMoreOpen, setReadMoreOpen] = React.useState(false);
  const {
    handleToActivate,
    handleRecommended,
    setRecommendedActivated,
    recommendedActivated,
  } = useAddonsContext();

  const contentfulCategory = 'recommendedVas';
  const { setNotification } = useNotificationContext();
  const isAdminContext = useIsAdminPage();
  const { redirectToNext } = useRoutes();

  const {
    contractDetails,
    isLoading: isContractLoading,
    isError,
  } = useContractContext();

  const recommended = contractDetails?.recommendedAddon;

  if (isError) return <ErrorFallback />;
  if (isContractLoading || !contractDetails) return <Spinner />;
  if (!recommended) return <span>No recommended addon</span>;

  const serializedProductName = recommended.tariffId.trim();

  const review = {
    name: t(`${contentfulCategory}.${serializedProductName}.review.name`),
    date: t(`${contentfulCategory}.${serializedProductName}.review.date`),
    content: t(`${contentfulCategory}.${serializedProductName}.review.content`),
    footer: t(`${contentfulCategory}.${serializedProductName}.review.footer`),
  };

  async function onClick(addProduct = true) {
    if (!addProduct || !recommended) return redirectToNext?.();
    setNotification({
      text: t(`${contentfulCategory}.addedVas`),
      visible: true,
      icon: 'IconInfo',
    });
    setToastLoading(true);
    handleRecommended(recommended);
    handleToActivate(recommended);
    return setTimeout(() => {
      setRecommendedActivated(true);
      return redirectToNext?.();
    }, 4000);
  }

  const tickPoints = t<string, string[]>(
    `${contentfulCategory}.${recommended.tariffId.trim()}.tickPoint`,
    {
      returnObjects: true,
    }
  );

  if (recommendedActivated) return <Navigate to="/contract-summary" />;

  return (
    <>
      <ReadMoreVasModal
        addon={recommended}
        open={readMoreOpen}
        onClose={() => setReadMoreOpen(false)}
      />
      {i18n.exists(`${contentfulCategory}.${recommended.tariffId}.image`) ? (
        <Box margin="0 -1rem -12px" zIndex={9}>
          <img
            src={t(`${contentfulCategory}.${recommended.tariffId}.image`)}
            alt="running-girl"
            width="100%"
          />
        </Box>
      ) : null}
      <Box
        height="60px"
        marginTop="-25px"
        marginLeft="-1rem"
        marginRight="-1rem"
        zIndex={10}
        style={{
          background:
            'linear-gradient(90deg, #1E4F45 17.62%, rgba(30, 79, 69, 0) 509.69%)',
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          'clip-path': 'ellipse(closest-side farthest-side)',
          'margin-bottom': '-1rem',
        }}
      />
      <Box
        margin="-11px 0 1rem"
        padding="0 1rem"
        backgroundColor={colors.ivyGreen600}
        zIndex={11}
        marginLeft="-1rem"
        marginRight="-1rem"
      >
        <Box
          display="flex"
          flexDirection="column"
          alignItems="flex-start"
          justifyContent="space-between"
        >
          <ContentText
            size="xl"
            lineHeight={lineHeights.tight}
            marginBottom="0.5rem"
            color={colors.snowWhite}
          >
            {t(`addons.${recommended.tariffId}.title`)}
          </ContentText>
          <ContentText size="xl" color={colors.snowWhite}>
            {getProperPriceString(recommended.prices, t)}
          </ContentText>
        </Box>
        <Box margin="1rem 0">
          {Array.isArray(tickPoints)
            ? tickPoints.map((item, index) =>
                item?.trim() ? (
                  <TickItem
                    iconProps={{
                      color: colors.snowWhite,
                    }}
                    contentProps={{
                      color: colors.snowWhite,
                    }}
                    // eslint-disable-next-line react/no-array-index-key
                    key={`tickItem_${index}`}
                  >
                    {item}
                  </TickItem>
                ) : null
              )
            : null}
        </Box>
        <Link
          size="m"
          marginBottom="1rem"
          color={colors.emperorGrey}
          onClick={() => {
            setReadMoreOpen(true);
          }}
        >
          <FormattedEnhancedMessage i18nKey="common.readMore" />
        </Link>
      </Box>
      <Divider />
      <Box margin="1rem 0">
        <ContentText size="xl" weight={fontWeights.bold} marginBottom="1rem">
          <FormattedEnhancedMessage i18nKey="common.reviews" />
        </ContentText>
        <ReviewBox data={review} />
      </Box>
      <Box
        position="sticky"
        bottom="0"
        backgroundColor={colors.snowWhite}
        padding="1rem 2rem"
        margin="0 -1rem -1rem"
      >
        <GTMButton
          width="100%"
          marginBottom="0.5rem"
          padding="0"
          onClick={() => onClick()}
          disabled={toastLoading || isAdminContext}
          contentfulKey={`${contentfulCategory}.button`}
        >
          {t('common.add')} — {t(`addons.${recommended.tariffId}.title`)}
        </GTMButton>
        <Button
          width="100%"
          status="secondary"
          padding="0"
          onClick={() => onClick(false)}
          disabled={toastLoading || isAdminContext}
        >
          <FormattedEnhancedMessage i18nKey="common.notNow" />
        </Button>
      </Box>
    </>
  );
}

export default RecommendedVas;
