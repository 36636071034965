import PinInput from '@components/PinInput';
import BottomModal from '@containers/BottomModal';
import {
  Heading,
  ContentText,
  fontWeights,
  colors,
} from '@fortum/elemental-ui';
import useNotificationContext from '@hooks/useNotificationContext';
import useAppContext from '@hooks/useAppContext';
import FormattedEnhancedMessage from '@utils/intl-extenders/FormattedEnhancedMessage';
import React from 'react';
import useQuery from '@hooks/useQuery';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import useApi from '@hooks/useApi';
import { AxiosError } from 'axios';

const SendAgainButton = styled.button`
  background: transparent;
  border: none;
  color: ${({ color }) => color || 'inherit'};
  padding: 0;
`;

const PinCodeBox: React.FC = () => {
  const [value, setValue] = React.useState<string>('');
  const [isCorrect, setIsCorrect] = React.useState(true);
  const [resendLoading, setResendLoading] = React.useState(false);
  const digits = 4;
  const { t } = useTranslation();
  const contentfulCategory = 'landing.pincode';
  const { setNotification } = useNotificationContext();
  const { authToken, authorize } = useAppContext();
  const query: URLSearchParams = useQuery();
  const code = query.get('code');
  const { requestPincode } = useApi();

  const onResendClick = React.useCallback(async () => {
    if (!code) return;
    try {
      setResendLoading(true);
      await requestPincode(code);
      setResendLoading(false);
    } catch (error) {
      if (
        error instanceof AxiosError &&
        error.response?.data?.message === 'Link expired'
      ) {
        setNotification({
          text: t('errors.pincodeExpired'),
          visible: true,
          color: colors.powerRed600,
        });
      } else {
        setNotification({
          text: t('errors.initialPincode'),
          visible: true,
          color: colors.powerRed600,
        });
      }
      setResendLoading(false);
    }
  }, [code, requestPincode, setNotification, t]);

  // React.useEffect(() => {
  //   onResendClick();
  // }, [onResendClick]);

  React.useEffect(() => {
    if (value.length !== digits || !code) setIsCorrect(false);
    else authorize(code, value);
  }, [value, authorize, code]);

  React.useEffect(() => {
    if (value.length !== digits) return;
    if (authToken) {
      setTimeout(() => {
        setIsCorrect(true);
      }, 1500);
      setNotification((prev) => ({
        ...prev,
        text: '',
        visible: false,
      }));
    } else {
      setIsCorrect(false);
      setNotification((prev) => ({
        ...prev,
        text: t(`${contentfulCategory}.error.incorrectCode`),
        visible: true,
      }));
    }
  }, [authToken, setNotification, value, t]);

  if (isCorrect) return null;

  return (
    <BottomModal
      show={!authToken}
      contentProps={{
        padding: '2rem 2rem 3.5rem',
      }}
    >
      <Heading
        weight={fontWeights.bold}
        size={5}
        marginBottom="0.5rem"
        fontSize="1.25rem"
      >
        {t(`${contentfulCategory}.heading`)}
      </Heading>
      <ContentText>
        <FormattedEnhancedMessage i18nKey={`${contentfulCategory}.content`} />
        <SendAgainButton
          type="button"
          onClick={onResendClick}
          color={resendLoading ? colors.fogGrey : 'inherit'}
        >
          <FormattedEnhancedMessage
            i18nKey={`${contentfulCategory}.resend.cta`}
          />
        </SendAgainButton>
      </ContentText>
      <PinInput value={value} setValue={setValue} digits={digits} />
      <ContentText marginTop="1.5rem">
        <FormattedEnhancedMessage
          i18nKey={`${contentfulCategory}.resend.text`}
        />
      </ContentText>
    </BottomModal>
  );
};

export default PinCodeBox;
